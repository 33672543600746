import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from "react-select";
import { Option } from '../../../../app/interface/I_General'
import { setFilterContract } from '../../../../app/redux/filter/contractSlice'
import { useFetchListContractQuery } from '../../../../app/redux/contracts/listContracts'
import { useFetchContractQuery } from '../../../../app/redux/api/contract/getContract'
import { setFilter } from '../../../../app/redux/filter/filterSlice'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
    const {config} = useLayout()
    const {auth, logout, currentUser} = useAuth();
    
    const dispatch = useDispatch();

    const {data:contracts} = useFetchContractQuery(auth?.api_token);

    interface OptionSelected {
        id_contract: string;
    }
    const [optionsContract, setOptionsContract] = useState<Option[]>([]);
    const [formData, setFormData] = useState({            
            id_contract: '',
            name_contract: '',
            name_unit : '',
            client : ''
        });

    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null,fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "0";

        setFormData(prevState => ({
            ...prevState,            
            [fieldName]: value.toString(),
        }));

    };

  const fetchContracts = () => {
        
        if (contracts && contracts.data &&  Array.isArray(contracts.data))
        {            
            const allOption = {
                value: '',
                label: 'Todos',
            };

            const newOptionsContract = [allOption, ...contracts.data.map((contracts: { id: any; name: any; }) => ({
                value: contracts.id.toString(),
                label: contracts.name,
            }))];

            setOptionsContract(newOptionsContract);

            setFormData(prevState => ({
                ...prevState,            
                id_contract: contracts  && contracts.data.length > 0 ? contracts.data[0].id.toString() : '',
                name_contract: contracts  && contracts.data.length > 0 ? contracts.data[0].name.toString() : '',
                name_unit: contracts  && contracts.data.length > 0 ? contracts.data[0].name_unit.toString() : '',
                client: contracts  && contracts.data.length > 0 ? contracts.data[0].id_client.toString() : '',
            }));
            
        }
    };

    useEffect(()=> {
        fetchContracts();        
    },[contracts]);

    useEffect(() => {
       
        dispatch(setFilter(formData));
    }, [formData.id_contract]);


    return (
        <>
            
            <div className='app-navbar flex-shrink-0'>
                <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
                {/* <Search /> */}
                </div>

                {/* <div className={clsx('app-navbar-item', itemClass)}>
                <div id='kt_activities_toggle' className={btnClass}>
                    <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
                </div>
                </div> */}

                {/* <div className={clsx('app-navbar-item', itemClass)}>
                <div
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    className={btnClass}
                >
                    <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
                </div>
                <HeaderNotificationsMenu />
                </div> */}
                <div className="
                    menu 
                    menu-rounded  
                    menu-column 
                    menu-lg-row 
                    my-5 
                    my-lg-0 
                    align-items-stretch 
                    fw-semibold
                    px-2 px-lg-0
                ">
                    <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2">
                        
                        <div className="col-md-12">
                            <div className=''>
                                <span>Contrato: </span>
                                <Select
                                    styles={{ container: (base) => ({ ...base, zIndex: 12 }) }}
                                    className="w-450px"
                                    placeholder="Selecione um contrato"
                                    // value={optionsContract.find(option => option.value === formData.id_contract) || null}
                                    
                                    value={{value: formData.id_contract, label: optionsContract.find(option => option.value === formData.id_contract)?.label}}
                                    // onChange={(selectedOption) => {
                                    //     handleSelectChange(selectedOption, "id_contract");
                                    //     dispatch(setFilterContract(formData)); // Dispatch da ação quando o produto é selecionado
                                    // }}
                                    
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_contract")}
                                    options={optionsContract}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={clsx('app-navbar-item', itemClass)}>
                {/* <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
                    <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
                    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
                </div> */}
                </div>

                <div className={clsx('app-navbar-item', itemClass)}>
                <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
                </div>

                <div className={clsx('app-navbar-item', itemClass)}>
                <div
                    className={clsx('cursor-pointer w-35px h-35px d-flex align-items-center bg-opacity-50 bg-primary symbol symbol-35px symbol-md-40px', userAvatarClass)}
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                >
                {
                    <div className="d-flex justify-content-center col-md-12 text-light-secondary">
                        <span className="align-self-center text-center fs-5">{currentUser?.short_name || ''}</span>
                    </div>
                }
                {/* <img src={currentUser?.photo} alt='' /> */}
                </div>
                <HeaderUserMenu />
                </div>

                {config.app?.header?.default?.menu?.display && (
                <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
                    <div
                    className='btn btn-icon btn-active-color-primary w-35px h-35px'
                    id='kt_app_header_menu_toggle'
                    >
                    <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
                    </div>
                </div>
                )}
            </div>
        </>
    )
}

export {Navbar}
