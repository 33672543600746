import Swal from "sweetalert2"
import { AuthType } from "../../../../app/interface/I_General"
import { queryBoleto } from "../../../../app/modules/contract/_request"

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const  downloadBoletoPdf = async (token_boleto:string, fieldName:string, auth:any) =>{

    let timerInterval : any
    Swal.fire({
        title: 'Fazendo download!',
        html: 'Aguarde seu download começará em  <b></b> ',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            const b:any = Swal.getHtmlContainer()?.querySelector('b')
            timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
            }, 100)
        },
        willClose: () => {
            clearInterval(timerInterval)
        }
    }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {}
    })

    // const responseBoleto = await queryBoleto(token_boleto, auth);

    // if(  responseBoleto?.data)
    // {
        // Criar uma URL Blob a partir da string base64
        // const pdfBlob = base64ToBlob(responseBoleto?.data);

        // Criar uma URL temporária para o Blob
        // const pdfUrl = URL.createObjectURL(pdfBlob);

        // Criar um link de download e disparar o clique nele
        const downloadLink = document.createElement('a');
        // downloadLink.href = pdfUrl;
        downloadLink.href = `https://app.boletocloud.com/boleto/2via/atualizada/download/${token_boleto}`;
        // downloadLink.download = fieldName; // Nome do arquivo a ser baixado
        downloadLink.click();
    // }
    
}

function base64ToBlob(base64String:any) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: 'application/pdf' });
}
