import { IParticipant } from "../../../../app/interface/I_Client";
import { IParcels } from "../../../../app/interface/I_Parcel";
import { IInterfaceMap } from "../../../../app/interface/I_Table";
import { ChangeInMass } from "../../../../app/types/T_General"; 

export const interfaceMap: IInterfaceMap = {
    participante: {
        id: 0,
        initialName: "",
        link: "#",
        name: "",
        email: "",
        confirmation: "",
    } as IParticipant,

    parcels: {
        id: "0",
        number_parcel: "",
        id_contract: "",
        id_client: "",
        type_parcel: "",
        link: "#",
        form_payment: "",
        due_date: "",
        value: "",
        status: "",
  } as IParcels,

};

type DynamicFunction = (...args: any[]) => any;

// Um objeto com as funções disponíveis
// export const functionMap = {
//     deleteParcel: async function deleteParcel(ids:string[], auth:any): Promise<any>
//     {
//         const response = await deleteParcelAPI(ids, auth );
//         return response;
//     }
//     // ,changeParcelInMassa: async function changeParcelInMassa(data: ChangeInMass[], auth:any): Promise<any>
//     // {
//     //     const response = await changeParcelInMassaApi(data, auth );
//     //     return response;
//     // }

// };

interface Callable {
    [key: string]: (...args: any[]) => any;
  }
  
  export const functionMap: Callable = {
    // deleteParcelInMassa: async function deleteParcel(ids:string[], auth:any): Promise<any>
    // {
    //     const response = await deleteParcelAPI(ids, auth );
    //     return response;
    // },
    // changeParcelInMassa: async function changeParcelInMassa(ids:string[], data: ChangeInMass[], auth: any): Promise<any>
    // {
    //     const response = await changeParcelInMassaApi(ids, data, auth );
    //     return response;
    // },
    
  };

  export interface Option {
    value: string;
    label: string;
    field?: string;
    writeLabel?:string
}
  


